import React from "react";
import { useParams } from "react-router-dom";
import Getintouch from "../Getintouch";
import { serviceDetails } from "../Services";
import { BiArrowToRight, BiArrowToLeft } from "react-icons/bi";

const ServiceDetails = () => {
  const { name } = useParams();

  // Find the current service by name
  const currentIndex = serviceDetails.findIndex((service) => service.name === name);
  const servicesD = serviceDetails[currentIndex];

  if (!servicesD) {
    return <div>Service not found</div>;
  }

  // Determine the indices of the next and previous services
  const prevIndex = (currentIndex === 0) ? serviceDetails.length - 1 : currentIndex - 1;
  const nextIndex = (currentIndex === serviceDetails.length - 1) ? 0 : currentIndex + 1;

  const prevService = serviceDetails[prevIndex];
  const nextService = serviceDetails[nextIndex];

  return (
    <section>
      <div className="relative h-screen text-white overflow-hidden">
        <div
          className="absolute inset-0"
          style={{
            backgroundImage: `linear-gradient(rgba(219, 18, 11, 0) 4%, #222222 100%), url(${servicesD.img})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
          <h1 className="text-2xl md:text-5xl font-bold leading-tight mb-4">
            {servicesD.name}
          </h1>
        </div>
      </div>

      <div className="w-screen mx-auto max-w-7xl sm:px-6 lg:px-8 bg-slate-200 dark:bg-slate-700">
        <div className="my-12">
          <div className="items-center flex mx-auto max-w-7xl py-3 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 max-w-screen-lg">
            <div data-aos-duration="4000" data-aos="fade-right">
              <div>
                <h4 className="text-2xl md:text-3xl font-medium leading-tight">
                  Our Journey
                </h4>
                <p>{servicesD.details}</p>
              </div>
            </div>
            <div>
              <img
                data-aos-duration="4000"
                data-aos="fade-left"
                className="lg:flex hidden rounded-md w-full h-full"
                src={servicesD.detailimg}
                alt={servicesD.name}
              />
            </div>
          </div>
          <div data-aos-duration="4000" data-aos="fade-up">
            <p className="mb-4">{servicesD.moredetails}</p>
          </div>

          {/* Next and Previous Links */}
          <div
            data-aos-duration="4000"
            data-aos="fade-up"
            className="flex justify-between items-center"
          >
  
            <a
              href={`/services/servicedetails/${prevService.name}`}
              className="flex items-center gap-2 text-indigo-700"
            >
              <BiArrowToLeft className="text-2xl" />
              <span>Previous: {prevService.name}</span>
            </a>

        
            <a
              href={`/services/servicedetails/${nextService.name}`}
              className="flex items-center gap-2 text-indigo-700"
            >
              <span>Next: {nextService.name}</span>
              <BiArrowToRight className="text-2xl" />
            </a>
          </div>
        </div>

        <div>
          <Getintouch />
        </div>
      </div>
    </section>
  );
};

export default ServiceDetails;
