import React from 'react'
import { Outlet } from 'react-router-dom'
import DefaultNav from './header/DefaultNav'

const Outlets = () => {
  return (
    <div >
      <DefaultNav/>
      <Outlet />
    </div>
  )
}

export default Outlets;
