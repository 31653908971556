import React from "react";
import whiteLogo from "../images/Group 12.png";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaDribbble,
} from "react-icons/fa";

export const Footer = () => {
  return (
    <footer>
      <div class="text-white bg-gray-900 py-8">
        <div class="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8 py-4">
          <div class="flex flex-wrap justify-between">
            <div class="flex items-center md:px-4 my-4 w-full sm:w-auto">
              <a href="/" class="block">
                <img src={whiteLogo} alt="" style={{ width: "150px" }} />
              </a>
            </div>

            <div class="md:px-4 my-4 w-full sm:w-auto">
              <div>
                <h2 class="inline-block mb-1 text-2xl border-b-4 border-blue-600">
                  About Company
                </h2>
              </div>
              <ul class="leading-loose">
                <li className="footer_links">
                  <a href="/">
                    Home
                  </a>
                </li>
                <li className="footer_links">
                  <a href="about">
                    About
                  </a>
                </li>
                <li className="footer_links">
                  <a href="services">
                    Services
                  </a>
                </li>

                <li className="footer_links">
                  <a href="contact">
                    Contact Us
                  </a>
                </li>
              </ul>
            </div>

            <div class="md:px-4 my-4 w-full sm:w-auto xl:w-1/5">
              <div>
                <h2 class="inline-block text-2xl mb-3 border-b-4 border-blue-600">
                  Connect With Us
                </h2>
              </div>
              <div className="justify-between flex">
                <div>
                  <a
                    target="_blank" rel="noreferrer"
                    href="https://www.facebook.com/softwareelites"
                  >
                    <FaFacebook  onMouseEnter={(e) =>
                        (e.currentTarget.style.color = "#4F46E5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = "#ffff")
                      }
                      style={{ cursor: "pointer" }}
                      className="text-2xl"
                    />
                  </a>
                </div>
                <div>
                  <a
                    target="_blank" rel="noreferrer"
                    href="https://www.instagram.com/softwareelites?igsh=MTd1cXRkaXpmMG1ocQ=="
                  >
                    <FaInstagram  onMouseEnter={(e) =>
                        (e.currentTarget.style.color = "#4F46E5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = "#ffff")
                      }
                      style={{ cursor: "pointer" }}
                      className="text-2xl"
                    />
                  </a>
                </div>

                <div>
                  <a
                    target="_blank" rel="noreferrer"
                    href="https://www.linkedin.com/in/software-elites-700a95327?lipi=urn%3Ali%3Apage%3Ad_flagship3_profile_view_base_contact_details%3B7RHaD2aGQ7WzqWEWs6bXeg%3D%3D"
                  >
                    <FaLinkedin
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.color = "#4F46E5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = "#ffff")
                      }
                      style={{ cursor: "pointer" }}
                      className="text-2xl"
                    />
                  </a>
                </div>
                <div>
                  <a target="_blank" rel="noreferrer" href="https://dribbble.com/Softwareelites">
                    <FaDribbble  onMouseEnter={(e) =>
                        (e.currentTarget.style.color = "#4F46E5")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.color = "#ffff")
                      }
                      style={{ cursor: "pointer" }}
                      className="text-2xl"
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bg-gray-900 borderline py-6 text-white">
        <div class="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8  ">
          <div class="-mx-4 flex flex-wrap justify-between">
            <div class="justify-center flex px-4 w-full text-center sm:w-auto sm:text-left">
              Copyright @softwareelites.com, All Rights Reserved.
            </div>
            <div class="px-4 w-full text-center sm:w-auto sm:text-left">
              Designed by <a href="softwareelites.com">softwareelites.com</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};
