import React from "react";

import services from "../images/service.webp";
import Getintouch from "./Getintouch";
import development from "../images/coding.webp";
import management from "../images/developer.webp";
import graphics from "../images/graphics.webp";
import design from "../images/designing.webp";
import content from "../images/content.webp";
import QA from "../images/testings.webp";
import map from "../images/location.webp";
import review from "../images/webreview.webp";

export const Services = () => {
  return (
    <section className="w-full overflow-hidden">
      {/* Header and background image */}
      <div className="relative bg-gradient-to-r from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
        <div className="absolute inset-0 banner-color">
          <img
            src={services}
            alt="Background img"
            className="object-cover object-center w-full h-full"
          />
          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
          <h1 className="text-2xl md:text-5xl font-bold leading-tight mb-4">
            Our Services
          </h1>
          <p className="text-lg text-pink-700 mb-8">
            Crafting exceptional software that propels your vision.
          </p>
        </div>
      </div>

      <div className="w-screen mx-auto max-w-7xl px-4 pt-8 sm:px-6 lg:px-8 bg-slate-200 dark:bg-slate-700">
        <div className="my-4 flex justify-center">
          <h3 className="mx-4 mb-4 text-center capitalize text-2xl md:text-4xl font-bold text-shadesOfBlue">
            Our Services
          </h3>
        </div>
        {/* Service cards */}
        <div className="w-full mx-auto grid grid-cols-1 pb-8 lg:pb-16 lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 gap-4">
          {serviceDetails.map((service) => (
            <div
              key={service.name}
              className="relative flex flex-col bg-white shadow-lg rounded-sm"
            >
              <div className="relative overflow-hidden text-white bg-blue-gray-500 shadow-lg rounded-sm">
              <a href={`/services/servicedetails/${service.name}`}>  <img
                  src={service.img}
                  alt="service-img"
                  className="w-full h-48 object-cover"
                />
                </a>
              </div>
              <div className="px-3 pt-3 pb-5">
                <a href={`/services/servicedetails/${service.name}`}>
         
                  <h2 className="text-md font-bold">{service.name}</h2>
                </a>
                <a href={`/services/servicedetails/${service.name}`}>
           
                  <p className="text-sm">{service.content.substring(0, 130)}</p>
                </a>
                <a href={`/services/servicedetails/${service.name}`}>
                  <button className="mt-2 bg-indigo-600 text-white py-2 px-3 rounded-sm">
                    Read More
                  </button>
                </a>
              </div>
            </div>
          ))}
        </div>

        <div>
          <Getintouch />
        </div>
      </div>
    </section>
  );
};

export const serviceDetails = [
  {
    img: development,
    name: "Web & App Development",
    content:
      "Web & App Development is one of the core services offered by our company, aimed at helping ...",
    detailimg: development,
    details:
      "Web & App Development is one of the core services offered by our company, aimed at helping businesses establish a strong digital presence. The company specializes in building custom websites and applications that are tailored to meet the unique needs of their clients. From user-friendly interfaces to seamless functionality, SoftwareElites focuses on creating digital solutions that are both visually appealing and highly functional. Their web development services include everything from responsive website design, e-commerce platforms, and CMS-based websites to complex web applications. Meanwhile, their app development team delivers robust mobile applications across platforms like iOS and Android, ensuring optimal performance and user experience.",
    moredetails:
      "At SoftwareElites, the development process is rooted in collaboration with clients to ensure that each project reflects the brand’s goals and objectives. They employ modern development technologies and frameworks, such as React, Node.js, Flutter, and more, to build scalable and secure web and mobile solutions. The company’s end-to-end approach covers every stage of the development lifecycle, from initial concept and wireframing to design, coding, testing, and deployment. This comprehensive service ensures that clients not only receive a product that works flawlessly but one that drives user engagement and business growth.",
  },
  {
    img: management,
    name: "Web & App Management",
    content:
      "Web & App Management is a comprehensive service focused on maintaining and optimizing ...",
    detailimg: management,
    details:
      "Web & App Management is a comprehensive service focused on maintaining and optimizing clients’ digital platforms over time. This service covers everything from performance monitoring, security updates, and bug fixes to regular content updates and feature enhancements. Managing a website or mobile application is not a one-time task, and SoftwareElites recognizes the need for constant attention to ensure these platforms remain functional, secure, and aligned with business goals. They take care of essential tasks such as monitoring server performance, ensuring uptime, and conducting regular security audits to protect against vulnerabilities and cyber threats. Additionally, they manage software updates, ensuring compatibility with evolving technologies and making sure the platforms are running on the latest versions of web or app frameworks.",
    moredetails:
      "What sets SoftwareElites apart in their Web & App Management service is their proactive approach to scaling and optimization. As a business grows, so do the demands on its digital platforms. SoftwareElites assists with scaling infrastructure to handle increased traffic, improving site speed, and optimizing mobile apps to ensure a smooth user experience. They provide tailored solutions such as performance analytics to identify bottlenecks and user behavior insights to improve user engagement. Beyond technical maintenance, they offer content management services, ensuring the site or app stays relevant with up-to-date information, fresh content, and new features as needed. In short, SoftwareElites' Web & App Management ensures that clients' digital assets are always running efficiently, are secure, and continue to evolve alongside business needs, making it a key offering for businesses looking to maintain a strong online presence.",
  },
  {
    img: graphics,
    name: "Graphics Designing",
    content:
      "Our commitment to crafting visually stunning and impactful designs that elevate brands and engage ...",
    detailimg: graphics,
    details:
      "Our commitment to crafting visually stunning and impactful designs that elevate brands and engage audiences sets us apart in the industry. Through Graphics Designing, we help businesses build a strong visual identity with compelling and cohesive design elements that resonate. Whether it's logos, branding materials, social media graphics, website layouts, or mobile app interfaces, we ensure that every design not only looks visually appealing but also aligns with strategic goals. Our services span a wide range of design needs, including brochures, business cards, and promotional materials, all tailored to help businesses communicate their message effectively in today’s visually-driven world.",
    moredetails:
      "Our team of experienced graphic designers approaches every project with a mix of creativity and strategic thinking, ensuring that each design aligns with the client's brand goals and resonates with their target market. This design process is highly collaborative, engaging clients at every step—from brainstorming ideas to delivering the final product—ensuring the designs reflect their vision. The focus is not just on creating beautiful visuals, but on producing functional, user-centric designs that enhance the user experience, especially in web and app interfaces. By staying current with modern design trends and integrating industry best practices, SoftwareElites consistently delivers graphic design solutions that captivate and drive meaningful engagement.",
  },
  {
    img: design,
    name: "UI & UX Designing",
    content:
      "Delivering exceptional digital experiences requires a deep understanding of both user needs ...",
    detailimg: design,
    details:
      "Delivering exceptional digital experiences requires a deep understanding of both user needs and design principles. In UI & UX Designing, SoftwareElites puts the user at the center of the design process, focusing on crafting seamless, intuitive, and aesthetically pleasing interactions. Our UI (User Interface) design emphasizes the look and feel of digital products, ensuring that interfaces are visually engaging, consistent, and aligned with brand identity. On the other hand, our UX (User Experience) design delves into the usability, accessibility, and overall functionality of the product, ensuring that every user journey is smooth, logical, and efficient. Our approach begins with comprehensive user research to understand the target audience, their behaviors, pain points, and preferences. We use this insight to inform every design decision, from wireframes to high-fidelity prototypes, ensuring the final product is tailored to the end user. During the prototyping phase, we meticulously plan user flows and interaction models to optimize the experience.",
    moredetails:
      "Whether it’s a web platform or a mobile application, we design interfaces that not only captivate users visually but also guide them effortlessly through the product, leading to higher engagement and satisfaction. Additionally, we prioritize usability testing and feedback integration throughout the design process. This iterative approach helps us refine the product to ensure it meets both business goals and user expectations. By addressing issues like navigation complexity, information architecture, and responsiveness across devices, we create adaptable solutions that work flawlessly in a range of environments. Ultimately, SoftwareElites’ UI & UX services are about bridging the gap between creativity and functionality. We aim to enhance user interaction while maintaining a keen focus on the strategic goals of the business, leading to digital products that not only look great but also perform exceptionally well in the market.",
  },
  {
    img: content,
    name: "Content Creation",
    content:
      "In today's digital landscape, effective communication is the cornerstone of building strong relationships ...",
    detailimg: content,
    details:
      "In today's digital landscape, effective communication is the cornerstone of building strong relationships with clients, customers, and partners. SoftwareElites offers comprehensive content creation services that cover a wide range of communication needs, including blog writing, business letters, email writing, and newsletters. Our approach goes beyond just generating content—we focus on crafting messages that resonate, engage, and drive action. Blog writing is a key element of our content creation service. We create informative, engaging, and SEO-optimized blog posts that help businesses connect with their audience, showcase industry expertise, and improve their search engine rankings. Whether the goal is to educate, inform, or entertain, we ensure each blog is tailored to the target audience and business objectives.",
    moredetails:
      "In addition to blog writing, we provide professionally written business letters and email correspondence. From formal letters that convey professionalism and authority to personalized emails that nurture client relationships, our team ensures that every piece of communication reflects your brand’s voice and values. Our email writing services are particularly useful for businesses seeking to implement effective email marketing campaigns or strengthen internal and external communication. Newsletters are another powerful tool we help businesses leverage. We craft visually appealing and content-rich newsletters that keep your audience informed about new products, services, updates, and company news. With strategic content and design, our newsletters help businesses maintain regular contact with their clients and nurture long-term relationships. Overall, our content creation services are designed to meet the diverse communication needs of businesses, helping them build a strong brand presence, foster client relationships, and achieve their marketing goals. Each piece of content is meticulously researched, written, and edited to ensure clarity, relevance, and engagement.",
  },
  {
    img: QA,
    name: "Quality Assuarance & Testing",
    content: "One of the most important to-dos in the development cycle is Quality Assurance (QA) & Testing. We ensures that...",
    detailimg: QA,
    details:
      "One of the most important to-dos in the development cycle is Quality Assurance (QA) & Testing. We ensures that the final product meets the highest standards of performance, reliability, and user satisfaction. At SoftwareElites, our QA and Testing services are designed to identify and resolve issues at every stage of the development process, from initial coding to post-launch support. We focus on delivering robust, bug-free applications that perform flawlessly under real-world conditions, helping to minimize risks and optimize the overall user experience. Our approach encompasses both manual and automated testing methods. We carry out functional testing to verify that each feature works as intended, performance testing to validate system stability under varying loads, and usability testing to ensure a seamless and intuitive user experience. Furthermore, security testing is a key component of our process, ensuring your application is protected from potential vulnerabilities. By adhering to best practices and thorough testing protocols, SoftwareElites ensures that projects not only meet industry standards but also align with specific business goals, resulting in reliable, high-performing software solutions that enhance customer satisfaction.",
    moredetails:
      "In addition to our comprehensive testing methods, we also emphasize continuous quality control throughout the development lifecycle. At SoftwareElites, QA isn’t an afterthought but an integral part of each development phase. We collaborate closely with development teams to implement early detection and prevention strategies, ensuring issues are addressed before they evolve into critical problems. This proactive approach reduces development time, mitigates costly fixes, and ensures a smooth deployment process. Furthermore, our post-launch testing services provide ongoing support to ensure that updates, feature additions, and system scaling are executed without disrupting the user experience. We perform regression testing to confirm that new changes don’t negatively impact existing functionalities. This commitment to long-term quality assurance reflects our dedication to delivering software that not only meets immediate project requirements but also sustains optimal performance as your business evolves. With SoftwareElites, you can be confident that your product will perform consistently, offering reliability and satisfaction to users long after its launch.",
  },
  {
    img: map,
    name: "Creating Google map Locations",
    content:
      "Creating Google Map locations is an essential service for businesses looking to enhance their online presence ...",
    detailimg: map,
    details:
      "Creating Google Map locations is an essential service for businesses looking to enhance their online presence and provide customers with accurate, easy-to-access location details. At SoftwareElites, we offer a streamlined service to set up and optimize your Google Maps listing, ensuring that your business can be easily found by potential customers. The process begins by accurately pinpointing your business location on Google Maps. This includes creating a Google My Business profile if it doesn't already exist, verifying the business, and ensuring all relevant information is up-to-date. We make sure key details such as your address, business hours, contact information, and website are prominently displayed.",
    moredetails:
      "Additionally, we optimize the listing by incorporating high-quality images, customer reviews, and a detailed business description that helps your business stand out. By leveraging local SEO techniques, we ensure that your Google Maps listing appears prominently when users search for services or businesses in your area. This boosts visibility, drives foot traffic, and improves customer trust, especially when your location is clearly and accurately mapped. For businesses with multiple locations, we handle the setup and management of each, ensuring consistency and accuracy across all listings. Through this service, SoftwareElites helps businesses enhance their discoverability, improve customer engagement, and establish a credible local presence.",
  },
  {
    img: review,
    name: "Computer Training",
    content:
      "Computer Training is an invaluable service that empowers individuals and teams with the skills needed to ...",
    detailimg: review,
    details:
      "Computer Training is an invaluable service that empowers individuals and teams with the skills needed to thrive in today’s technology-driven world. At SoftwareElites, we offer comprehensive training programs tailored to different levels of expertise, from beginners to advanced users. Our aim is to equip clients with the knowledge and hands-on experience they need to navigate and utilize various computer systems, software, and tools effectively. Our training covers a wide range of topics, including basic computer literacy, software applications like Microsoft Office Suite, data management, and internet usage. For more advanced learners, we offer specialized training in programming languages, web development, cybersecurity, and data analytics. Each training program is designed with practical, real-world applications in mind, ensuring that participants can directly apply what they've learned in their daily work or personal projects.",
    moredetails:
      "Through a blend of interactive lessons, tutorials, and hands-on projects, we foster a supportive learning environment that encourages participants to build confidence in their technical abilities. Whether your goal is to improve productivity, stay current with industry trends, or advance your career, our tailored computer training programs are designed to meet your specific needs. With expert instructors and up-to-date materials, SoftwareElites ensures that individuals and organizations are well-prepared to succeed in an increasingly digital landscape.",
  },
];
