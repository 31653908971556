import React from "react";
import intouch from "../images/timothy.jpg";

const Getintouch = () => {
  return (
    <div data-aos-duration="4000" data-aos="fade-up" class="pb-16 justify-center flex items-center">
      <div className="items-center bg-gray-200 flex mx-auto  max-w-7xl  grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-2 max-w-screen-lg">
      <img data-aos-duration="4000" data-aos="fade-left"
          className="object-cover w-full h-full object-center order-1 md:order-2"
          src={intouch}
          alt="Winding mountain road"
        />

        <div data-aos-duration="4000" data-aos="fade-right" className="flex md:p-8 p-4 flex-col md:px-16 order-2 md:order-1">
          <h2 className="text-2xl font-medium text-black lg:text-3xl">
            Connect For Support
          </h2> 
          <p className="mt-2">
            We're here to guide you in your software needs! Whether questions,
            assistance, or want to discuss your next big project, our team is
            available. Connect with us anytime, and let’s build great solutions
            together!
          </p>

          <div className="mt-3">
            <a
              href="/contact"
              className="inline-block w-full text-center text-sm font-medium text-white bg-indigo-600 rounded-md border-solid border-2 border-gray-600 py-3 px-8 transition-transform duration-300 ease-in-out transform md:w-48"
            >
              Connect With Us
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Getintouch;
