import React, { useState } from "react";
import about from "../images/about_1.webp";
import Journey from "../images/services.webp";
import offer from "../images/computer-8779040_1280.webp";
import { BsFillRocketTakeoffFill } from "react-icons/bs";
import { IoTelescopeSharp } from "react-icons/io5";
import { PiHandshakeFill } from "react-icons/pi";
import { GrCloudSoftware } from "react-icons/gr";
import { PiLadderFill } from "react-icons/pi";
import { FaPeopleCarry } from "react-icons/fa";
import Getintouch from "./Getintouch";

const Activities = [
  {
    title: "Integrity",
    icon: <PiHandshakeFill />,
    text: "We deliver reliable, transparent, and ethical software solutions, consistently prioritizing customers' trust and satisfaction.",
  },
  {
    title: "Teamwork",
    icon: <FaPeopleCarry />,
    text: "We work in hand with our team staff members to support and empower our customers, ensuring their success and satisfaction.",
  },
  {
    title: "Creativity",
    icon: <GrCloudSoftware />,
    text: "Our trained team is well equipped and ready to deliver outstanding software services to our clients.",
  },
  {
    title: "Loyalty",
    icon: <PiLadderFill />,
    text: "We are committed to addressing our customers' needs and supporting them in achieving their goals effectively.",
  },
];

const targets = [
  {
    icon: <IoTelescopeSharp />,
    title: "Our Vision",
    text: "To provide tecnology support, Expand and Establish tecnology services to all parts of the world.3",
  },
  {
    icon: <BsFillRocketTakeoffFill />,
    title: "Our Mission",
    text: "To educate and provide employment opportunities to massess and Boost technological services.",
  },
];
export const About = () => {
  const [flippedIndex, setFlippedIndex] = useState(null);

  const handleCardHover = (index) => {
    setFlippedIndex(index);
  };

  return (
    <section className="w-full overflow-hidden"> 
      <div className="relative bg-white from-purple-600 to-blue-600 h-screen text-white overflow-hidden">
        <div className="absolute inset-0">
          <img
            src={about}
            alt="BaxkgroundImg"
            className="object-cover object-center w-full h-full"
          />

          <div className="absolute inset-0 bg-black opacity-50"></div>
        </div>

        <div className="relative z-10 flex flex-col justify-center items-center h-full text-center">
          <h1 class="text-2xl md:text-5xl font-bold leading-tight mb-4">
            About Us
          </h1>
        </div>
      </div>

      <div  className="items-center flex mx-auto max-w-7xl px-4 py-3 sm:px-6 lg:px-8 my-12 grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 gap-4 mx-auto max-w-screen-lg">
        <div data-aos-duration="4000" data-aos="fade-right" className="md:px-4 px-0">
          <div>
            <h4 className="text-2xl md:text-3xl font-medium leading-tight">
              Our Journey
            </h4>
            <p className="mb-4">
              We are a testament to the evolution of passionate, innovativative,
              and relentless pursuit of excellence software services. From
              humble beginnings, we started with a vision to create software
              solutions that redefine how businesses operate and thrive in a
              digital world. Our commitment to pushing boundaries and embracing
              new challenges has been the cornerstone of our success. Every
              project, every line of code, and every client interaction has
              shaped who we are today. As we look to the future, we remain
              driven by the same values that sparked our inception—integrity,
              creativity, and a steadfast dedication to empowering our clients
              with innovative solutions. Our journey is far from over; it’s an
              ongoing adventure fueled by the desire to make a lasting impact in
              the world of technology.
            </p>
          </div>

          <div  className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 mt-12 mx-auto max-w-7xl gap-5">
            {targets.map((activity, index) => (
              <div
                className="flex"
                key={index}
                onMouseEnter={() => handleCardHover(index)}
                onMouseLeave={() => setFlippedIndex(null)}
              >
                <div
                  className={`card ${
                    flippedIndex === index ? "flipped" : ""
                  } relative w-full shadow-lg rounded-lg transition-transform transform ${
                    flippedIndex === index ? "rotate-y-180" : ""
                  }`}
                >
                  <div
                    className={`card-body front ${
                      flippedIndex === index ? "hidden" : "block"
                    } absolute inset-0 flex justify-center items-center backface-hidden`}
                  >
                    <div className="mb-2 flex flex-col items-center">
                      <div className="text-5xl mb-3 text-indigo-700 cursor-pointer">
                        {activity.icon}
                      </div>

                      <h4 className="text-xl md:text-2xl font-medium leading-tight text-center">
                        {activity.title}
                      </h4>
                    </div>
                  </div>

                  <div
                    className={`card-body back ${
                      flippedIndex === index ? "block" : "hidden"
                    } absolute inset-0 p-5 transform rotate-y-180 backface-hidden`}
                  >
                    <p className="text-md">{activity.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
        <img data-aos-duration="4000" data-aos="fade-left"
          className="lg:flex hidden rounded-md w-full h-full"
          src={Journey}
          alt=""
        />
      </div>

      <div  className="items-center  bg-gray-200  flex mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 my-12 grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 gap-4 mx-auto max-w-screen-lg">
        <div data-aos-duration="4000" data-aos="fade-right" className="order-2 md:order-1">
          <img
            class="rounded-md w-full h-full object-cover "
            src={offer}
            alt='""'
            typeof="foaf:Image"
          />
        </div>

        <div data-aos-duration="4000" data-aos="fade-left" className="order-1 md:px-4 px-0 md:order-2">
          <div className="text-2xl md:text-3xl font-medium leading-tight mb-3">
            What we Offer
          </div>

          <p className="mb-4 text-base">
            We offer software solutions which include creation of web
            application & maintainence, mobile application & maintainence,
            Wireframing & Prototyping, Archietectural design, Site mapping,
            Content Creation, Blog wwriting, Quality assurance, Testing,
            Graphics designing, Hosting, SEOs and other related services.
          </p>
        </div>
      </div>

      <div data-aos-duration="4000" data-aos="fade-up" className="mt-12 mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="text-center text-2xl md:text-3xl font-medium leading-tight mb-5">
          <h4>Our Core Values</h4>
        </div>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 mt-12 mx-auto max-w-7xl pb-8 lg:pb-16 gap-5">
          {Activities.map((activity, index) => (
            <div
              className="flex "
              key={index}
              onMouseEnter={() => handleCardHover(index)}
              onMouseLeave={() => setFlippedIndex(null)}
            >
              <div
                className={`card ${
                  flippedIndex === index ? "flipped" : ""
                } relative w-full shadow-lg rounded-lg transition-transform transform ${
                  flippedIndex === index ? "rotate-y-180" : ""
                }`}
              >
                <div
                  className={`card-body front ${
                    flippedIndex === index ? "hidden" : "block"
                  } absolute inset-0 flex justify-center items-center backface-hidden`}
                >
                  <div className="mb-2 flex flex-col items-center">
                    <div className="text-5xl mb-3 text-indigo-700 cursor-pointer">
                      {activity.icon}
                    </div>

                    <h4 className="text-xl md:text-2xl font-medium leading-tight text-center">
                      {activity.title}
                    </h4>
                  </div>
                </div>

                <div
                  className={`card-body back ${
                    flippedIndex === index ? "block" : "hidden"
                  } absolute inset-0 p-5 transform rotate-y-180 backface-hidden`}
                >
                  <p className="text-md">{activity.text}</p>
                </div>
              </div>
            </div>
          ))}
        </div>

        <div>
          <Getintouch />
        </div>
      </div>
    </section>
  );
};
