import { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./App.css";
import { About } from "./body/About";
import { Home } from "./body/Home";
import { Services } from "./body/Services";
import { Contact } from "./body/Contact";
import Main from "./Main";
import Outlets from "./Outlets";
import ServiceDetails from "./body/Servicedetails/ServiceDetails";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {

  useEffect(() => {
    AOS.init({
      duration: 1500,
      once: true,
    });
  }, []);

  return (
   
    <Routes>
      <Route element={<Outlets />}>
        <Route path="/" element={<Home />} />
      </Route>

      <Route element={<Main />}>
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="services" element={<Services />} />
        <Route
          path="/services/servicedetails/:name"
          element={<ServiceDetails />}
        />
      </Route>
    </Routes>
   
  );
}

export default App;
